import React from "react";

function AboutUs() {
  return (
    <div className="container-fluid mt-5 mb-5">
      <h2 className="title mb-5">Qui Sommes Nous ?</h2>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="card-about">
              <img src="https://i.imgur.com/p9Kam0A.png" alt="scootX" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-about-text">
              <p className="parag mt-3">
                Scootx est la première société spécialisée dans la location de
                trottinettes électriques en Tunisie.
              </p>
              <p className="parag mt-3">
                Oubliez les embouteillages, les problèmes de stationnement et
                les transports en commun surchargés.
              </p>
              <button className="button-scootx">Plus</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
