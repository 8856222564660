import React, { useState } from "react";

function Reservation() {
  const [nombreScooter, setNombreScooter] = useState(0);

  const increment = () => {
    setNombreScooter(nombreScooter + 1);
  };
  const decrement = () => {
    if (nombreScooter > 0) {
      setNombreScooter(nombreScooter - 1);
    }
  };
  return (
    <div className="container">
      <div className="formulaire">
        <div className="btn-switch-container">
          <button className="switch1">Personne</button>
          <button className="switch2">Sociéte</button>
        </div>
        <div className="form">
          <div className="form-div">
            <label>Nom & Prenom :</label>
            <input type="text" />
          </div>
          <div className="form-div">
            <label>Email :</label>
            <input type="text" />
          </div>
          <div className="form-div">
            <label>Phone :</label>
            <input type="text" />
          </div>
          <div className="form-div">
            <label>Durée De Location :</label>
            <input type="text" />
          </div>
          <div className="form-div">
            <label>Nombre De Trottinettes :</label>
            <div className="container-form-btn">
              <button onClick={decrement}>-</button> <p>{nombreScooter}</p>{" "}
              <button onClick={increment}>+</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reservation;
