import React from "react";
import Navbar from "../Navbar/Navbar";

function Header() {
  return (
    <div className="container-fluid header">
      <Navbar />
      <div className="container">
      
          <div className="header-container">
            <h1 className="header-title">Another Way To Ride</h1>
            <p className="header-text">
              Louer une trottinette n'a jamais été aussi facile. Il vous suffit
              de remplir le formulaire de location et de vous présenter à notre
              point de location situé sur les berges du lac, à côté de la
              station Wash & Go.
            </p>
            <a className="header-btn"> Réservation </a>
          </div>
        </div>
      </div>
    
  );
}

export default Header;
