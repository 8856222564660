import React from "react";

function Footer() {
  return (
    <div className="container-fluid footer">
      
        <div className="logo-footer"> <img src="https://i.imgur.com/r43E85i.png" alt="ScootX" style={{width:"150px"}} /></div>
        <div className="social-footer">
        <i class="bi bi-facebook"></i>
        <i class="bi bi-instagram"></i>
        <i class="bi bi-tiktok"></i>
        </div>
      </div>
  
  );
}

export default Footer;
