import React from "react";

function Vehicule() {

  return (
    <div className="container-fluid mt-5 mb-5">
      <h2 className="title mb-5">Nos Véhicules</h2>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="card-vihecule">
              <div className="card-vihecule-descreption">
                <h4 className="title-card-vihecule">Essential Trottinette Noir</h4>
                <ul style={{ listStyle: "none" }}>
                  <li>Vitesse maximale : 20 km/h</li>
                  <li>Autonomie : jusqu’à 20km.</li>
                  <li>Capacité totale : 183Wh </li>
                  <li>Régulateur de vitesse sophistiqué</li>
                  <li>Facilement pliable en 3 secondes</li>
                </ul>
              </div>
              <div className="card-vihecule-image">
                <img src="https://i.imgur.com/CRX2I14.png" alt="scootx" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card-vihecule">
              <div className="card-vihecule-descreption">
                <h4 className="title-card-vihecule">Electric Scooter 3</h4>
                <ul style={{ listStyle: "none" }}>
                  <li>Vitesse maximale : 25 km/h</li>
                  <li>Autonomie : 30km</li>
                  <li>Puissance maximal : 600W </li>
                  <li>Ecran LED avec vitesse</li>
                  <li>Facilement pliable en 3 secondes</li>

                </ul>
              </div>
              <div className="card-vihecule-image">
                <img src="https://i.imgur.com/XpF6OPR.jpg" alt="scootx" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vehicule;
