import React from 'react'
import {NavLink} from 'react-router-dom'
import './Navbar.css'
function Navbar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  return (
    <div>
     <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src="https://i.imgur.com/r43E85i.png" alt="ScootX" />
            
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/4fun"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Qui Sommes Nous
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/takeAndGo"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Nos véhicules  
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/Culture"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Réservation
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                Contacter Nous
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </ div>
  )
}

export default Navbar


