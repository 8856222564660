import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/Home/Home'
const TakeAndGo = () => {
  return (
    <div>
      <h1>  ScootX Take & Go</h1>
    </div>
  );
};
const ForFun = () => {
  return (
    <div>
      <h1>  ScootX 4 fun</h1>
    </div>
  );
};
const CircuitCulturel = () => {
  return (
    <div>
      <h1>Curcuit culturel</h1>
    </div>
  );
};
const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
    </div>
  );
};
function App() {
  return (

     
       <BrowserRouter>
        {/* <Navbar /> */}

          <Routes>
            <Route exact path="/" element={<Home/>} />
            {/* <Route path="/takeAndGo" element={<TakeAndGo/>} />
            <Route path="/4fun" element={<ForFun/>} />
            <Route path="/Culture" element={<CircuitCulturel/>} />
            <Route path="/contact" element={<Contact/>} /> */}
          </Routes>
      
      </BrowserRouter>
 
  );
}

export default App;
