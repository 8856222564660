import React, { useState } from "react";
import axios from "axios";
import "./res.css";
function Reservation2() {
  const [isLogin, setIsLogin] = useState(true);
  // const [nombreScooter, setNombreScooter] = useState(0);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dateLocation, setDateLocation] = useState();
  const [heureLocation, setHeureLocation] = useState();
  const [DureeLocation, setDureeLocation] = useState();
  const [CompanyName, setCompanyName] = useState("");
  const [adresse, setAdresse] = useState("");
  const [nbrVih, setNbrVih] = useState(0);
  const increment = () => {
    setNbrVih(nbrVih + 1);
  };
  const decrement = () => {
    if (nbrVih > 0) {
      setNbrVih(nbrVih - 1);
    }
  };

  const reservationClient = (e) => {
    e.preventDefault();
    axios.post("http://localhost:5001/client/", {
      nom,
      prenom,
      phone,
      email,
      dateLocation,
      heureLocation,
      DureeLocation,
      nbrVih,
    });
    setNom("");
    setPrenom("");
    setPhone("");
    setEmail("");
    setDateLocation("");
    setHeureLocation("");
    setDateLocation(0);
    setNbrVih(0);
  };

  const reservationSociete = (e) => {
    e.preventDefault();
    axios.post("http://localhost:5001/societe/", {
     CompanyName,
      phone,
      email,
      dateLocation,
      heureLocation,
      DureeLocation,
      adresse,
      nbrVih,
    });
    setCompanyName("");
    setAdresse("");
    setPhone("");
    setEmail("");
    setDateLocation("");
    setHeureLocation("");
    setDateLocation(0);
    setNbrVih(0);
  };

  return (
    <div className="container-fluid">
      <h2 className="title mb-5">Réservation</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="parag">Comment louer une trottinette électrique ?</p>
            <p className="parag">
              Il vous suffit de remplir le formulaire de location
            </p>
            <button className="button-scootx">Plus</button>
          </div>
          <div className="col-md-6">
            <div className="wrapper">
              <div className="title-text">
                {isLogin ? (
                  <div className="title login" style={{ marginLeft: "0" }}>
                    Personne
                  </div>
                ) : (
                  <div className="title login" style={{ marginLeft: "-50%" }}>
                    Personne
                  </div>
                )}
                <div className="title signup">Société</div>
              </div>
              <div className="form-container">
                <div className="slide-controls">
                  {isLogin ? (
                    <input
                      type="radio"
                      name="slide"
                      id="login"
                      defaultChecked
                    />
                  ) : (
                    <input type="radio" name="slide" id="signup" checked />
                  )}
                  <label
                    htmlFor="login"
                    className="slide login"
                    onClick={() => setIsLogin(true)}
                  >
                    Personne
                  </label>
                  <label
                    htmlFor="signup"
                    className="slide signup"
                    onClick={() => setIsLogin(false)}
                  >
                    Société
                  </label>
                  <div className="slider-tab" />
                </div>
                <div className="form-inner">
                  {!isLogin ? (
                    <form
                      className="login"
                      style={{ marginLeft: "-50%" }}
                      onSubmit={reservationClient}
                    >
                      <div className="form-div">
                        <label>Nom :</label>
                        <input
                          type="text"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Prénom :</label>
                        <input
                          type="text"
                          value={prenom}
                          onChange={(e) => setPrenom(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Email :</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Phone :</label>
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Date de Location :</label>
                        <input
                          type="Date"
                          value={dateLocation}
                          onChange={(e) => setDateLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Heure de Location :</label>
                        <input
                          type="time"
                          value={heureLocation}
                          onChange={(e) => setHeureLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Durée De Location (Heure) :</label>
                        <input
                          type="number"
                          value={DureeLocation}
                          onChange={(e) => setDureeLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Nombre De Trottinettes :</label>
                        <div className="container-form-btn">
                          <a onClick={decrement}>-</a> <p>{nbrVih}</p>{" "}
                          <a onClick={increment}>+</a>
                        </div>
                      </div>
                      <button className="reservation-btn" type="submit">
                        Réserver
                      </button>
                    </form>
                  ) : (
                    <form
                      onSubmit={reservationClient}
                      className="login"
                      style={{ marginLeft: "0" }}
                    >
                      <div className="form-div">
                        <label>Nom :</label>
                        <input
                          type="text"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Prénom :</label>
                        <input
                          type="text"
                          value={prenom}
                          onChange={(e) => setPrenom(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Email :</label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Phone :</label>
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Date de Location :</label>
                        <input
                          type="Date"
                          value={dateLocation}
                          onChange={(e) => setDateLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Heure de Location :</label>
                        <input
                          type="time"
                          value={heureLocation}
                          onChange={(e) => setHeureLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Durée De Location (Heure) :</label>
                        <input
                          type="number"
                          value={DureeLocation}
                          onChange={(e) => setDureeLocation(e.target.value)}
                        />
                      </div>
                      <div className="form-div">
                        <label>Nombre De Trottinettes :</label>
                        <div className="container-form-btn">
                          <a onClick={decrement}>-</a> <p>{nbrVih}</p>{" "}
                          <a onClick={increment}>+</a>
                        </div>
                      </div>
                      <button type="submit" className="reservation-btn">
                        Réserver
                      </button>
                    </form>
                  )}
                  <form className="login" style={{ marginLeft: "0" }} onSubmit={reservationSociete}>
                    <div className="form-div">
                      <label>Nom De La Société :</label>
                      <input
                        type="text"
                        value={CompanyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Email :</label>
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Phone :</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Adresse :</label>
                      <input
                        type="text"
                        value={adresse}
                        onChange={(e) => setAdresse(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Date de Location :</label>
                      <input
                        type="Date"
                        value={dateLocation}
                        onChange={(e) => setDateLocation(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Heure de Location :</label>
                      <input
                        type="time"
                        value={heureLocation}
                        onChange={(e) => setHeureLocation(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Durée De Location (Heure) :</label>
                      <input
                        type="number"
                        value={DureeLocation}
                        onChange={(e) => setDureeLocation(e.target.value)}
                      />
                    </div>
                    <div className="form-div">
                      <label>Nombre De Trottinettes :</label>
                      <div className="container-form-btn">
                        <a onClick={decrement}>-</a> <p>{nbrVih}</p>{" "}
                        <a onClick={increment}>+</a>
                      </div>
                    </div>
                    <button className="reservation-btn" type="submit">Réserver</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reservation2;
