import React from "react";
import AboutUs from "../AboutUS/AboutUs";
import Slider from "../slider/Slider";
import Comment from "../CommentLouer/CommentLouer";
import Vehicule from "../Vehicule/Vehicule";
import Header from "../header/Header";
import Reservation from "../Reservation/Reservation";
import Reservation2 from "../Reservation/Reservation2";
import Footer from "../Footer/Footer";
function Home() {
  return (
    <>
      <Header />
      <AboutUs />
      <Comment />
      <Vehicule/>
      <Reservation2/>
      <Footer/>
    </>
  );
}

export default Home;
