import React from "react";

function Comment() {
  return (
    <div className="container mt-5 pt-5">
      <div className="row">
        <div className="col-md-6">
          <img src="https://i.imgur.com/9uuxA6z.jpg" alt="scootX-location" />
        </div>
        <div className="col-md-6 d-flex justify-content-center flex-column">
          <h2 className="title mb-3" style={{marginLeft:'0',paddingLeft:"0"}}>Comment louer une trottinette électrique ?</h2>
          <p className="parag text-justify">
            Louer une trottinette n'a jamais été aussi facile. Il vous suffit de
            remplir le formulaire de location et de vous présenter à notre point
            de location situé sur les berges du lac, à côté de la station Wash &
            Go. Une fois arrivé, veuillez présenter votre pièce d'identité et
            vous serez pris en charge par notre agent pour finaliser la
            location.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Comment;
